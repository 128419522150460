import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const QuestionCarousel = ({ items, title, selectedItem, onChange }) => (
    <div className="col-md-6 justify-content-center" style={{ textAlign: "center" }}>
        <h4 style={{color:'black'}}>{title}</h4>
        <Carousel
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={selectedItem}
            onChange={onChange}
        >
            {items.map((item, i) => (
                <div style={{ backgroundColor: "#FAFBFE", padding: 10 }} key={i}>
                    <p>{i + 1}/{items.length.toString()}</p>
                    <p style={{color:'black'}}>{item.questionText}</p>
                    <ol style={{ textAlign: 'left' }}>
                        {item.answers.map((answer, i) => (
                            <li key={i} style={{ color: answer.correct ? 'green' : 'black' }}>
                                {answer.answerText}
                            </li>
                        ))}
                    </ol>
                </div>
            ))}
        </Carousel>
    </div>
);

export default QuestionCarousel;
