import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {base} from '../../base';
import '../../styles/css/AdminMain.css';
const CryptoJS = require("crypto-js");

class SetUpWebHooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersCreatedLink: '',
            loading:true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.webHooksVariablesRef = base.syncState(`webHooksVariables`, {
            context: this,
            state: 'webHooksVariables',
            then(){
                this.setState({
                    loading: false
                })
            },
            onFailure(e){
                console.log(e);
                this.setState({
                    loading: false
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.webHooksVariablesRef);
    }

    handleChange(evt){
        const webHooksVariablesCopy = this.state.webHooksVariables || {};
        const target = evt.target;
        const name = target.name;
        webHooksVariablesCopy[name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ webHooksVariables: webHooksVariablesCopy });
    }

    generateKey(size = 32, format = 'base64') {
        const buffer = CryptoJS.lib.WordArray.random(size);
        const webHooksVariablesCopy = { ...this.state.webHooksVariables };
        webHooksVariablesCopy.currentApiKey = buffer.toString(CryptoJS.enc.Hex);
        this.setState({ webHooksVariables: webHooksVariablesCopy });
    }

    deleteKey(){
        this.setState({
            webHooksVariables: null
        })
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        const webHooksVariables = this.state.webHooksVariables || {};
        // const isDev = process.env.REACT_APP_FIREBASE_PROJECT_ID === "sqwadbingodev";
        const isSqwadControlled = process.env.REACT_APP_SQWAD_CONTROL_KEYS === "true";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel" style={{color:"black"}}>
                <p className="admin-header-text" style={{marginBottom:0}}>Web Hooks</p>
                <p className="admin-subheader-text">Your auth key can be found here</p>
                {webHooksVariables.currentApiKey &&
                    <div style={{marginLeft: 20, color:"black"}}>
                        Current Key: <br/>{webHooksVariables.currentApiKey}
                        {!isSqwadControlled &&
                            <>
                                <button onClick={()=>this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginLeft: 20}}>
                                    <span className="fa fa-refresh"/> Generate New Key
                                </button>
                                <button onClick={()=>this.deleteKey()} className="btn btn-primary btn-lg delete-button" id="submitButton" style={{marginLeft: 20}}>
                                    <span className="fa fa-trash"/> Delete
                                </button>
                            </>
                        }
                    </div>
                }
                {!webHooksVariables.currentApiKey && !isSqwadControlled &&
                    <div>
                        <button onClick={()=>this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px', marginLeft: 20}}>
                            <span className="fa fa-refresh"/> Generate Key
                        </button>
                    </div>
                }
                <p className="admin-subheader-text">Manage your web hooks here that will send messages to links you specify when certain actions are taken</p>
                <ol>
                    <li>
                        <div className="mb-3">
                            <label htmlFor="sender" className="form-label">New Users Web Hook</label>
                            <div className="form-text">This web hook fires every time a new account is created, please enter a url that you would like to have notified of user creation</div>
                            <input id="userCreationWebHookLink" name="userCreationWebHookLink" type="url" className="form-control" value={webHooksVariables.userCreationWebHookLink} onChange={this.handleChange} />
                        </div>
                    </li>
                    <li>
                        <div className="mb-3">
                            <label htmlFor="sender" className="form-label">Prizes Won Web Hook</label>
                            <div className="form-text">This web hook fires every time a new prize is won, please enter a url that you would like to have notified of user creation</div>
                            <input id="prizeWonWebHookLink" name="prizeWonWebHookLink" type="url" className="form-control" value={webHooksVariables.prizeWonWebHookLink} onChange={this.handleChange} />
                        </div>
                    </li>
                    <li>
                        <div className="mb-3">
                            <label htmlFor="sender" className="form-label">End Form Submit Web Hook</label>
                            <div className="form-text">This web hook fires every time an end form is submitted, please enter a url that you would like to have notified of form submission</div>
                            <input id="afterGameFormSubmitWebHookLink" name="afterGameFormSubmitWebHookLink" type="url" className="form-control" value={webHooksVariables.afterGameFormSubmitWebHookLink} onChange={this.handleChange} />
                        </div>
                    </li>
                </ol>
                <p className="admin-header-text" style={{marginBottom:0}}>Documentation</p>
                <p className="admin-subheader-text">How to use Sqwad web hooks</p>
                <a href="/webhooksdocumentation" style={{marginLeft: 20}}>See the docs</a>
            </div>
         </div>
        );
    }
}

export default SetUpWebHooks;
