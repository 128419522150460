import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import swal from 'sweetalert2';
import UploadImage from '../utils/UploadImage';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import ReactPlayer from 'react-player';

class SetUpQuestions extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        let hasBetaFeatures = false;
        if(["sqwadtriviademo","trivia-dev-faa4f"].indexOf(process.env.REACT_APP_FIREBASE_PROJECT_ID) !== -1){
            hasBetaFeatures = true;
        }
        this.state = {
            questionsList: [],
            editingTicket: false,
            selectedQuestion: {},
            newAnswerText: '',
            newAnswerImage: null,
            newAnswerImagePreview: {},
            currentAnswerList: [],
            currentQuestionTitle: '',
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            editTicketId: "",
            loading:true,
            add_image: false,
            add_video: false,
            videoLength: null,
            videoLink: "",
            hasBetaFeatures: hasBetaFeatures,
            explanationText: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.showAutoGenerateModal = this.showAutoGenerateModal.bind(this);
    }

    componentDidMount(){
        this.questionsListRef = base.syncState(`questionsList`, {
            context: this,
            state: 'questionsList',
            asArray: true
        });
        this.setState({loading:false})
    }

    componentWillUnmount() {
      base.removeBinding(this.questionsListRef);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleSubmit(event) {
      event.preventDefault();
      if(this.state.currentAnswerList.length < 1){
        swal({
          title: 'No Answers Detected!',
          text: 'Make sure to add answers to your question before creating it',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
      }

    }

    onDrop(files, rejected, myArgument) {
        if(rejected.length > 0){
            swal({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const nameToUpdate = myArgument + "Preview";
        const fileToUpdate = files[0];
        this.setState({
            [nameToUpdate]: ({
                fileToUpdate,
                preview: URL.createObjectURL(fileToUpdate)
            })
        })

    }

    handleVideoChange(evt){
        const name_of_file = evt.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false})
            if(res && res.error){
                swal({
                    title: 'Video cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res && res.imageUrl) {
                vm.setState({
                    fileUploaded: true,
                    videoLink: res.imageUrl
                });
            } else {
                swal({
                    title: 'Video cannot be uploaded',
                    text: "Something went wrong, please re-upload your video and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    createQuestion() {
        const questionTitle = this.state.currentQuestionTitle || this.state.selectedQuestion.questionText;
        const add_video = this.state.add_video || false;
        const makeSurveyQuestion = this.state.makeSurveyQuestion || false;
        let videoLink = this.state.videoLink;
        let videoLength = this.state.videoLength;
        const answerList = this.state.currentAnswerList;
        const explanationText = this.state.explanationText || "";
      if(!questionTitle){
        swal({
            title: "No Question",
            text: 'Make sure to fill out the question section!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
        if (add_video && videoLink && !this.validUrl(videoLink)){
            swal({
                title: 'Oh uh!',
                text: 'Video link is not a valid URL',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(add_video && !videoLink){
            swal({
                title: 'Oh uh!',
                text: "You have indicated you want to add a video but haven't added a video link nor a video file",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(!add_video){
            videoLink = false
            videoLength = false
        }
      if((answerList.length === 0 || answerList.length === 1 || answerList.length > 4)){
        swal({
            title: "Incorrect Answers",
            text: 'You need to add more answers or, if you have more than 4, take away answers to this question',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      let correctAnswer = null;
      for(let answer in answerList){
        if(answerList[answer].correct){
          correctAnswer = answerList[answer];
        }
      }
      if(!correctAnswer && !makeSurveyQuestion){
        swal({
            title: "No Correct Answer",
            text: 'Make sure you set one of the answers correct!',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
        return;
      }
      let selectedQuestion = this.state.selectedQuestion;
      if(Object.keys(selectedQuestion).length === 0){
        selectedQuestion = null;
      }
        for(let answer in answerList){
            answerList[answer].answerImagePreview = null;
        }
      const vm = this;
      if(!selectedQuestion){
        base.push('questionsList', {
          data: {questionText: questionTitle, answers: answerList, videoLink: videoLink, videoLength: videoLength, makeSurveyQuestion: makeSurveyQuestion, explanationText: explanationText},
          then(err){
            if(!err){
              vm.setState({
                modal: false,
                selectedQuestion: {},
                currentAnswerList: [],
                videoLink: "",
                videoLength: null,
                add_video: false,
                add_image: false,
                makeSurveyQuestion: false,
                currentQuestionTitle: "",
                newAnswerText: ""
              })
            }
          }
        })
      } else {
        const selectedQuestionId = selectedQuestion.key;
        base.post(`questionsList/${selectedQuestionId}`, {
          data: {questionText: questionTitle, answers: answerList, videoLink: videoLink, videoLength: videoLength, makeSurveyQuestion: makeSurveyQuestion, explanationText: explanationText},
          then(err){
            if(!err){
              vm.setState({
                modal: false,
                selectedQuestion: {},
                currentAnswerList: [],
                videoLink: "",
                videoLength: null,
                add_video: false,
                add_image: false,
                makeSurveyQuestion: false,
                currentQuestionTitle: "",
                newAnswerText: ""
              });
            }
          }
        });
      }
    }

    validUrl(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    async answerToLocalArray(){
      let array = this.state.currentAnswerList;
      let newAnswerText = this.state.newAnswerText || "";
      newAnswerText = newAnswerText.trim();
      const newAnswerImage = this.state.newAnswerImagePreview || null;
      const add_image = this.state.add_image || false;
      let newAnswerImagePreview = null;
      if(newAnswerImage && add_image){
          newAnswerImagePreview = newAnswerImage.preview;
      }
      if(array.length>3){
          swal({
              title: "Too many answers",
              text: 'Only 4 answers are allowed per question',
              type: 'warning',
              confirmButtonText: 'Ok'
          });
          return;
      }
      let imageUrl = null;
      if(newAnswerText === ""){
        swal({
            title: "Answer is blank",
            text: 'Please enter an answer and try again',
            type: 'warning',
            confirmButtonText: 'Ok'
          });
        return;
      }
    // if(newAnswerText.length > 72){
    //     swal({
    //         title: "Answer Is Too Long",
    //         text: 'Answer cannot be longer than 72 characters',
    //         type: 'warning',
    //         confirmButtonText: 'Ok'
    //     });
    //     return;
    // }
      if(newAnswerImagePreview && !this.validUrl(newAnswerImagePreview)){
          this.setState({
              loading:true
          });
          await this.UploadImage.upload_file(newAnswerImage.fileToUpdate).then(res => {
              if(res && res.imageUrl){
                  imageUrl = res.imageUrl;
              }
          })
      } else if(newAnswerImagePreview && this.validUrl(newAnswerImagePreview)){
          imageUrl = newAnswerImagePreview;
      }
      if(newAnswerImagePreview && !imageUrl){
          this.setState({
              loading:false
          });
          swal({
              title: 'Image cannot be uploaded',
              text: 'There was some issue with the image upload, please reupload and try again',
              type: 'warning',
              confirmButtonText: 'Ok'
          })
          return;
      }
      const answerId = appDatabasePrimaryFunctions.ref().push().key;
      let newAnswerObject = {answerText: newAnswerText, id: answerId, answerImage: imageUrl, answerImagePreview: newAnswerImagePreview, order:array.length+1};
      array = array.concat(newAnswerObject);
      this.setState({
        currentAnswerList: array,
        newAnswerText: "",
        newAnswerImagePreview: null,
        loading: false,
      })
    }

    editQuestion(event){
        event.preventDefault();
        const array = this.state.questionsList;
        const question = array[event.target.value];
        let addVideo = false;
        if(question && question.videoLink){
            addVideo = true
        } else if(!question){
            swal({
                title: 'Oh uh!',
                text: 'Something went wrong, refresh the page and try again!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        let advanced = false;
        if(question.makeSurveyQuestion || question.explanationText){
            advanced = true;
        }
        this.setState({
            modal: !this.state.modal,
            currentQuestionTitle: question.questionText,
            videoLink: question.videoLink || "",
            add_video: addVideo,
            makeSurveyQuestion: question.makeSurveyQuestion,
            videoLength: question.videoLength,
            selectedQuestion: question,
            currentAnswerList: question.answers.slice(0),
            explanationText: question.explanationText,
            advanced: advanced
        });
    }

    changeOrder(item, direction){
        let currentAnswerList = this.state.currentAnswerList;
        let originalOrder = item.order;
        if(originalOrder === 1 && direction === 1){
            return
        } else if(originalOrder === currentAnswerList.length && direction ===-1){
            return
        }
        let newOrder = originalOrder - direction;
        for(let currentAnswerIndex in currentAnswerList){
            if(newOrder === currentAnswerList[currentAnswerIndex].order){
                currentAnswerList[currentAnswerIndex].order = originalOrder
            } else if(item.id === currentAnswerList[currentAnswerIndex].id){
                currentAnswerList[currentAnswerIndex].order = newOrder;
            }
        }
        this.setState({
            currentAnswerList: currentAnswerList
        })
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.questionsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Question?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this question in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result) {
            base.remove('questionsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        editingTicket: false,
        selectedQuestion: {},
        currentAnswerList: [],
        currentQuestionTitle: '',
        makeSurveyQuestion: false,
        explanationText: ''
      })
    }

    removeFromAnswerArray(index){
      let answerArray = this.state.currentAnswerList;
      answerArray.splice(index, 1);
      while(index < answerArray.length){
          if(answerArray[index]){
              answerArray[index].order = index+1
          } else {
              index = answerArray.length+1;
          }
          index++
      }
      this.setState({
        currentAnswerList: answerArray
      })
    }

    editAnswer(answer,index){
        const answerText = answer.answerText;
        const answerImage = answer.answerImage;
        this.removeFromAnswerArray(index);
        this.setState({
            newAnswerText: answerText,
            newAnswerImagePreview: {
                preview: answerImage
            },
            newAnswerImage: null,
        })
    }

    setAsCorrect(e){
      let answers = this.state.currentAnswerList;
      for(let answer in answers){
        if(answer == e){
          answers[answer].correct = true
        } else {
          answers[answer].correct = false
        }
      }
      this.setState({
        currentAnswerList: answers
      })
    }

    async autoGenerateQuestion(){
        let {trueFalse=false, numberOfAnswers=2, query=process.env.REACT_APP_CLEAN_CLIENT_NAME} = this.state || {};
        if(trueFalse) {
            numberOfAnswers = null;
        } else {
            if(!numberOfAnswers) return;
        }
        const queryObject = {};
        if(!query){
            swal({
                title: 'Uh oh!',
                text: 'No topic detected, please provide one',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        }
        queryObject['team'] = query;
        queryObject['numberOfAnswers'] = numberOfAnswers;
        queryObject['trueOrFalse'] = trueFalse;
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/generateTriviaQuestions`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/generateTriviaQuestions`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify(queryObject)
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
        } catch (e) {
            console.log("ERROR: ", e);
            this.setState({loading: false})
            swal({
                title: 'Uh oh!',
                text: 'Something went wrong please try again',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const responseJson = await Promise.resolve(response.json());
        const answersArray = [];
        for(const i in responseJson.message.options){
            const options = responseJson.message.options[i];
            const newAnswerObject = {answerText: options, id: appDatabasePrimaryFunctions.ref().push().key, order:parseInt(i)+1};
            if(parseInt(i) === parseInt(responseJson.message.correct)){
                newAnswerObject['correct'] = true
            }
            answersArray.push(newAnswerObject);
        }
        this.setState({
            currentQuestionTitle: responseJson.message.question,
            currentAnswerList: answersArray,
            loading: false,
            autoGenerateModal: false
        })
    }

    showAutoGenerateModal(){
        this.setState({
            autoGenerateModal: !this.state.autoGenerateModal
        })
    }

    render() {
      const questionsList = this.state.questionsList;
      let currentAnswerList = this.state.currentAnswerList;
      const newAnswerImagePreview = this.state.newAnswerImagePreview || {};
      let selectedQuestion = this.state.selectedQuestion;
      if(Object.keys(selectedQuestion).length === 0){
        selectedQuestion = null;
      }
      if(currentAnswerList.length > 1){
          currentAnswerList = currentAnswerList.sort((a,b)=>a.order-b.order);
      }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none'}}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Questions</p>
                    <p className="admin-subheader-text">These are questions fans will respond to during the game</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Question</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        questionsList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.questionText}</p>
                                  <ul style={{textAlign:"left"}}>
                                      {
                                          item.answers.sort((a,b)=>a.order-b.order).map(function(item,t){
                                              return (
                                                  <li key={t} style={{listStyle:"none"}}>
                                                      - {item.answerText}
                                                      { item.answerImage &&
                                                         <img src={item.answerImage} width="50px" height="50px" alt=""/>
                                                      }
                                                  </li>
                                              )
                                          })
                                      }
                                  </ul>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editQuestion.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader toggle={this.toggle}>Add Question <span style={{ cursor: 'pointer', display: this.state.hasBetaFeatures ? '' : "none" }} onClick={() => this.showAutoGenerateModal()}>🪄</span></ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group" >
                            <label htmlFor="rewardName">Question</label>
                            <textarea id="currentQuestionTitle" name="currentQuestionTitle" className="form-control" value={this.state.currentQuestionTitle} onChange={this.handleChange} placeholder="What is the number 1 winter sport in Jamaica?"/>
                        </div>
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="add_video" name="add_video" type="checkbox" checked={this.state.add_video} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="add_video" style={{padding:0,margin:0}}>Add Video (Optional) <span style={{display: this.state.add_video?"":"none"}}>-- Recommended: Dimensions = 16:9, Size = 1280x720, less then 20MB</span></label>
                        </div>
                        <div style={{display: this.state.add_video ? "" : "none"}}>
                            <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                <div className="col-md-6">
                                    <label htmlFor="videoLink">Winning Video Link</label>
                                    <input type="url" id="videoLink" name="videoLink" className="form-control" value={this.state.videoLink} onChange={this.handleChange} placeholder="https://mywinningvideolink.com"/>
                                </div>
                                <div className="col-md-1">
                                    <h2><b>OR</b></h2>
                                </div>
                                <div className="col-md-2">
                                    <input style={{display:'none'}}  id="raceVideoFile" name="raceVideoFile" type="file" ref={input => {this.raceVideoFile = input; }} onChange={this.handleVideoChange}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('raceVideoFile').click()} />
                                </div>
                            </div>
                            <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.videoLink? '' : 'none'}}>
                                Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({videoLink: null, fileUploaded:false}, () => {
                                document.getElementById('videoLink').value="";
                            })}> ❌</span>
                                {this.state.add_video && this.state.videoLink && !this.state.videoLength &&
                                <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                }
                                <ReactPlayer
                                    style={{display: this.state.videoLink? '' : 'none'}}
                                    url={this.state.videoLink}
                                    onDuration={(e)=> this.setState({videoLength: e})}
                                    onError={(e)=>this.setState({videoLength: null})}
                                    muted={true}
                                    playing={true}
                                    controls={true}
                                    preload={"auto"}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="advanced" name="advanced" type="checkbox" checked={this.state.advanced} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="advanced" style={{padding:0,margin:0}}>Advanced</label>
                        </div>
                        {this.state.advanced &&
                            <>
                                <div className="form-group row mb-3">
                                    <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                        <input id="makeSurveyQuestion" name="makeSurveyQuestion" type="checkbox" checked={this.state.makeSurveyQuestion} onChange={this.handleChange} />
                                    </span>
                                    <label className="col-md-10 col-form-label" htmlFor="makeSurveyQuestion" style={{padding:0,margin:0}}>Make Survey Question</label>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyTitle">Explanation Text: </label>
                                    <input id="explanationText" name="explanationText" type="text" value={this.state.explanationText} onChange={this.handleChange} className="form-control" placeholder="Why the answer is correct"/>
                                </div>
                            </>
                        }
                        <h4>Answer Details</h4>
                        <div className="form-group">
                          <label htmlFor="companyTitle">Add Answer Text: </label>
                          <input id="answerText" name="newAnswerText" type="text" value={this.state.newAnswerText} onChange={this.handleChange} className="form-control" placeholder="The Answer To Your Question"/>
                        </div>
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="add_image" name="add_image" type="checkbox" checked={this.state.add_image} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" style={{padding:0,margin:0}} htmlFor="companyTitle">Add Answer Image (Optional): </label>
                            <Dropzone
                                  style={{display: this.state.add_image ? "block" : "none", margin: "auto"}}
                                  className="dropzone dz-clickable"
                                  accept="image/*"
                                  onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'newAnswerImage')}}
                                  multiple={false}
                                  maxSize={2200000}>
                                  <div className="dz-message needsclick">
                                      <span className="fa fa-cloud-upload text-muted" style={{display: newAnswerImagePreview.preview ? 'none' : ''}}/>
                                      <h3 style={{display: newAnswerImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                      <img
                                          style={{display: newAnswerImagePreview.preview ? '' : 'none'}}
                                          src={newAnswerImagePreview.preview}
                                          width="100px"
                                          height="auto"
                                          alt="Drop here"
                                      />
                                  </div>
                              </Dropzone>
                        </div>
                        <center>
                            <button className="btn btn-primary btn-lg" onClick={()=>this.answerToLocalArray()}><span className="fa fa-plus"/> ADD ANSWER</button>
                        </center>
                        <ol>
                          {
                              currentAnswerList.map(function(item,index){
                                  return(
                                    <li key={index} style={{marginTop: 10}}>Answer: {item.answerText}
                                        <img src={item.answerImagePreview || item.answerImage} width="50px" height="50px" alt="" style={{display:item.answerImage?"":"none"}}/>
                                        <span style={{marginLeft:"40px"}}>
                                            {!this.state.makeSurveyQuestion &&
                                                <button className="btn btn-success btn-admin" onClick={() => this.setAsCorrect(index)} style={{display: item.correct ? 'none' : ''}}>SET AS CORRECT</button>
                                            }
                                            <span className="btn btn-success btn-admin" style={{display: item.correct ? '' : 'none'}}>CORRECT</span>
                                            <div style={{display:"inline-block", marginLeft:10}}>
                                                <button style={{display: index === 0?"none":""}} onClick={()=>this.changeOrder(item, 1)}>⬆</button>
                                                <button style={{display: currentAnswerList.length === index+1? "none":""}} onClick={()=>this.changeOrder(item, -1)}>⬇</button>
                                            </div>
                                            <button className="btn btn-primary btn-admin" style={{marginLeft:"40px"}} onClick={()=>this.editAnswer(item,index)}><span className="fa fa-pencil"/></button>
                                            <button className="btn btn-danger btn-admin" style={{float:"right"}} onClick={()=>this.removeFromAnswerArray(index)}><span className="fa fa-trash"/></button>
                                        </span>
                                    </li>
                                  )
                              }, this)
                          }
                        </ol>
                        <div className="form-group text-center">
                          <button disabled={this.state.add_video && this.state.videoLink && !this.state.videoLength} className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createQuestion()}>Submit Question</button>
                        </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.autoGenerateModal} toggle={this.showAutoGenerateModal} style={{width: '90%'}} id="myModal">
              <ModalHeader toggle={this.showAutoGenerateModal}>Question Generator (BETA)</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div>
                            <p>Caution! This is a beta feature and may be unstable. Verify that any auto generated question is correct before creating. It also is on the free tier for now and so will be very slow on the first creation... be patient!</p>
                        </div>
                        <div className="form-group" >
                            <label htmlFor="rewardName">Topic (optional)</label>
                            <input type="text" id="query" name="query" className="form-control" value={this.state.query} onChange={this.handleChange} placeholder={this.state.query || process.env.REACT_APP_CLEAN_CLIENT_NAME}/>
                        </div>
                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="trueFalse" name="trueFalse" type="checkbox" checked={this.state.trueFalse} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="trueFalse" style={{padding:0,margin:0}}>True Or False</label>
                        </div>
                        {!this.state.trueFalse &&
                            <div className="form-group" >
                                <label htmlFor="numberOfAnswers">Number of Answers (optional)</label>
                                <input max={4} min={2} step="1" type="number" id="numberOfAnswers" name="numberOfAnswers" className="form-control" value={this.state.numberOfAnswers} onChange={this.handleChange} placeholder="2"/>
                            </div>
                        }
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.autoGenerateQuestion()}>Generate!</button>
                        </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpQuestions
