import Rebase from 're-base';
import firebase from 'firebase/app';
import database from 'firebase/database';
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID
};

const app = firebase.initializeApp(config);
const appDatabasePrimaryFunctions = firebase.database(app);
const base = Rebase.createClass(appDatabasePrimaryFunctions);

export { app, appDatabasePrimaryFunctions, base, firebase}
