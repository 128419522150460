import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {appDatabasePrimaryFunctions, base, firebase} from "../../base";
import swal from "sweetalert2";
import {Modal} from "reactstrap";
import {isObjectEmpty} from "../utils/HelpfulFunction";

let interval;

class ScoreboardControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: 0
        }
    }

    convertSecondsToMinutesFormat(seconds){
        let fullTime = new Date(1000 * seconds).toISOString().substr(11, 8)
        let splitDate = fullTime.split(":");
        return parseInt(splitDate[1]).toString() + ":" + splitDate[2]
    }

    componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame'
        });
        this.questionsListRef = base.bindToState('questionsList',{
            context: this,
            state: 'questionsList',
            asArray: true
        });
        this.timeLeftRef = base.syncState('currentGame/timeLeft', {
            context: this,
            state: 'timeLeft',
        })
        let serverTimeOffset = 0;
        this.serverTimeOffsetRef = base.listenTo(".info/serverTimeOffset", {
            context: this,
            then(data){
                serverTimeOffset = data;
            }
        })
        this.startQuestionToShowTimerRef = base.listenTo("currentGame/startQuestionToShowTimer", {
            context: this,
            then(data){
                const variables = this.state.tenantVariables || {};
                let seconds = variables.questionDisplayLength || 5;
                let currentGame = this.state.currentGame || {};
                seconds = parseInt(seconds);
                const startQuestionToShowTimer = data;
                if(!startQuestionToShowTimer || typeof startQuestionToShowTimer === "object" || !currentGame || !this.state.timerStatus){
                    if(interval){
                        clearInterval(interval);
                    }
                    this.setState({
                        timeLeft: "0"
                    })
                    return
                }
                if(interval){
                    clearInterval(interval);
                }
                interval = setInterval(() => {
                    const timeLeft = (seconds * 1000) - (Date.now() - startQuestionToShowTimer - serverTimeOffset);
                    if (timeLeft < 0) {
                        clearInterval(interval);
                        this.setAnswerTimer()
                        this.setState({
                            timeLeft: "0"
                        })
                    }
                    else {
                        let calculatedSeconds = Math.ceil(timeLeft/1000);
                        // let finalCleanTime = this.convertSecondsToMinutesFormat(calculatedSeconds);
                        this.setState({
                            timeLeft:calculatedSeconds
                        })
                    }
                }, 100)
            }
        })
        this.setState({loading:false})
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.questionsListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.emailVariablesRef);
        base.removeBinding(this.formQuestionsRef);
        base.removeBinding(this.timeLeftRef);
        base.removeBinding(this.serverTimeOffsetRef);
        base.removeBinding(this.startQuestionToShowTimerRef);
    }

    endGame(){
        const currentGame = this.state.currentGame || {};
        currentGame.screenToShow = 0;
        currentGame.questionToShow = 0;
        for(const questionIndex in currentGame.questions){
            const question = currentGame.questions[questionIndex];
            question.shown = false;
        }
        this.setState({
            timeModal: true,
            question: 0,
            currentGame: currentGame
        }, () => {
            this.setTimeouts()
        })
    }

    async setTimeouts() {
        const currentGame = this.state.currentGame;
        const questionNumber = this.state.question;
        const questionOn = (parseInt(questionNumber) + 1).toString();
        currentGame.screenToShow = 0;
        if(currentGame.gameTiming !== "immediate"){
            currentGame.rotate = true;
        }
        currentGame.questionToShow = this.state.question;
        currentGame.startQuestionToShowTimer = firebase.database.ServerValue.TIMESTAMP;
        this.setState({
            currentGame: currentGame,
            timerStatus: "Showing Question " + questionOn
        })
    }

    setAnswerTimer(){
        var tenantVariables = this.state.tenantVariables;
        var currentGame = this.state.currentGame
        var secondsShowingAnswer = tenantVariables.answerDisplayLength || "5";
        var secondsShowingAnswerConverted = parseInt(secondsShowingAnswer + "000", 10)
        secondsShowingAnswer = parseInt(secondsShowingAnswer, 10)
        currentGame.screenToShow = 2
        currentGame.questions[this.state.question].shown = true
        this.setState({
            timeLeft: secondsShowingAnswer,
            timerStatus: "Showing Correct Answer",
            currentGame: currentGame
        })
        var vm = this;
        var answerCountDown = setInterval(function(){
            secondsShowingAnswer = secondsShowingAnswer - 1;
            vm.setState({
                timeLeft: secondsShowingAnswer
            })
        }, 1000)
        var waitForAnswerTimeout = setTimeout(function (){
            clearInterval(answerCountDown);
            if(vm.state.question < vm.state.currentGame.questions.length - 1){
                var question = parseInt(vm.state.question, 10) + 1
                vm.setState({
                    question: question
                }, () => {
                    vm.setTimeouts()
                })
            } else {
                vm.setState({
                    timeLeft: 0,
                    timeModal: false,
                    currentGame: currentGame
                })
            }
        }, secondsShowingAnswerConverted)
        this.setState({
            AnswerTimeOutId: waitForAnswerTimeout,
            answerCountDownId: answerCountDown
        })
    }

    cancelCountDown(){
        if(this.state.questionTimeOutId){
            clearTimeout(this.state.questionTimeOutId);
        }
        if(this.state.AnswersTimeOutId){
            clearTimeout(this.state.AnswersTimeOutId);
        }
        if(this.state.AnswerTimeOutId){
            clearTimeout(this.state.AnswerTimeOutId);
        }
        if(this.state.questionCountDownId){
            clearInterval(this.state.questionCountDownId);
        }
        if(this.state.answersCountDownId){
            clearInterval(this.state.answersCountDownId);
        }
        if(this.state.answerCountDownId){
            clearInterval(this.state.answerCountDownId);
        }
        const currentGame = this.state.currentGame;
        currentGame.screenToShow = 0;
        currentGame.questionToShow = 0;
        currentGame.rotationEnd = false;
        currentGame.rotate = false;
        currentGame.startQuestionToShowTimer = null;
        for(const questionIndex in currentGame.questions){
            const question = currentGame.questions[questionIndex];
            question.shown = false;
        }

        this.setState({
            currentGame: currentGame,
            question: 0,
            timeModal: false,
        })
    }

    render() {
        let selectedGame = this.state.currentGame || null;
        let scoreboardText = "";
        if(selectedGame && selectedGame.id){
            if(selectedGame.gameTiming === "immediate"){
                scoreboardText = "Cycle Scoreboard"
            }
        }
        let timeLeft = this.state.timeLeft;
        if(isObjectEmpty(timeLeft)){
            timeLeft = ""
        }
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <SideMenu/>
                <TopMenu/>
                <div className="admin-main-panel">
                    {selectedGame && selectedGame.gameTiming === "immediate" &&
                        <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{fontSize: "0.8rem", float:'left', height:'52px', margin: 10 }}>{scoreboardText}</button>
                    }
                </div>
                <Modal isOpen={this.state.timeModal} style={{width: '90%'}} id="myTimeModal">
                    <div className="card">
                        <center className="card-body">
                            <h4 className="header-title mb-3"> {this.state.timerStatus}</h4>
                            <span style={{fontSize: "30px"}}>{timeLeft}</span>
                            <div>
                                <p>DO NOT REFRESH OR QUIT THE BROWSER WHILE THE TIMER IS RUNNING</p>
                                <button className="btn btn-danger" onClick={() => this.cancelCountDown()}>CANCEL COUNTDOWN AND RESET</button>
                            </div>
                        </center>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ScoreboardControl;
