import React, {Component} from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {base} from '../../base';
import '../../styles/css/AdminMain.css';
import swal from "sweetalert2";
import RichTextEditor from 'html-verify-react-rte';
import {detectHowManyCodesEntered, isEmptyHtmlEntity, validateEmail} from "../utils/HelpfulFunction";

const google = window.google;
let geocoder = new google.maps.Geocoder();
const isValidDomain = require('is-valid-domain')

class SetUpLoginVariables extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    componentDidMount(){
      this.tenantVariablesRef = base.syncState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
          then(){
              this.setState({
                  loading: false
              }, () => {
                  this.initializeTenantVariables();
              })
          }
      });
    }

    initializeTenantVariables() {
        const tenantVariables = this.state.tenantVariables || {};
        let advanced = false;
        if (
            tenantVariables.doNotCollectEmail ||
            tenantVariables.sanitizeEmails ||
            tenantVariables.allowList ||
            tenantVariables.blockList ||
            tenantVariables.collectDistance ||
            tenantVariables.noMandatoryTermsAndConditions ||
            tenantVariables.keepFansLoggedIn ||
            tenantVariables.gdprCompliance ||
            tenantVariables.collectYesNoQuestion
        ) {
            advanced = true;
        }
        if (tenantVariables) {
            let notAcceptableLocationMessage = tenantVariables.notAcceptableLocationMessage;
            let promotionText = tenantVariables.promotionText;
            let promotionTextTwo = tenantVariables.promotionTextTwo;
            let gdprComplianceMessage = tenantVariables.gdprComplianceMessage;
            let yesNoQuestionText = tenantVariables.yesNoQuestionText;
            let yesNoQuestionText2 = tenantVariables.yesNoQuestionText2;
            if (!notAcceptableLocationMessage) {
                notAcceptableLocationMessage = RichTextEditor.createEmptyValue();
            } else {
                notAcceptableLocationMessage = RichTextEditor.createValueFromString(notAcceptableLocationMessage, 'html');
            }
            if (!promotionText) {
                promotionText = RichTextEditor.createEmptyValue();
            } else {
                promotionText = RichTextEditor.createValueFromString(promotionText, 'html');
            }
            if(!promotionTextTwo){
                promotionTextTwo = RichTextEditor.createEmptyValue();
            } else {
                promotionTextTwo = RichTextEditor.createValueFromString(promotionTextTwo, 'html');
            }
            if (!gdprComplianceMessage) {
                gdprComplianceMessage = RichTextEditor.createEmptyValue();
            } else {
                gdprComplianceMessage = RichTextEditor.createValueFromString(gdprComplianceMessage, 'html');
            }
            if (!yesNoQuestionText) {
                yesNoQuestionText = RichTextEditor.createEmptyValue();
            } else {
                yesNoQuestionText = RichTextEditor.createValueFromString(yesNoQuestionText, 'html');
            }
            if (!yesNoQuestionText2) {
                yesNoQuestionText2 = RichTextEditor.createEmptyValue();
            } else {
                yesNoQuestionText2 = RichTextEditor.createValueFromString(yesNoQuestionText2, 'html');
            }
            this.setState({
                acceptableDistance: tenantVariables.acceptableDistance,
                locationPermissionsBody: tenantVariables.locationPermissionsBody,
                locationPermissionsHeader: tenantVariables.locationPermissionsHeader,
                formattedAddress: tenantVariables.formattedAddress,
                notAcceptableLocationMessage: notAcceptableLocationMessage,
                notAcceptableLocationHeader: tenantVariables.notAcceptableLocationHeader,
                customDropdownOptions: tenantVariables.customDropdownOptions,
                canPlayOutside: tenantVariables.canPlayOutside,
                allowList: tenantVariables.allowList,
                blockList: tenantVariables.blockList,
                promotionText: promotionText,
                promotionTextTwo: promotionTextTwo,
                advanced: advanced,
                gdprComplianceMessage: gdprComplianceMessage,
                yesNoQuestionText: yesNoQuestionText,
                yesNoQuestionText2: yesNoQuestionText2
            });
        }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    handleTextChange(evt){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[evt.target.name] = evt.target.value
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    selectOption(button){
      const tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[button] = !tenantVariablesCopy[button]
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    updatePromotionText(){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let promotionText = this.state.promotionText.toString('html');
        let promotionTextTwo = this.state.promotionTextTwo.toString('html');
        if(promotionText === "<p><br></p>"){
            promotionText = "";
        }
        if(promotionTextTwo === "<p><br></p>"){
            promotionTextTwo = "";
        }
        tenantVariablesCopy['promotionText'] = promotionText;
        tenantVariablesCopy['promotionTextTwo'] = promotionTextTwo;
        this.setState({
            tenantVariables:  tenantVariablesCopy,
            loading: false
        });
        swal({
            title: 'Updates Successful',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    updateDistance(){
        const tenantVariables = this.state.tenantVariables;
        if(!this.state.acceptableDistance || !this.state.formattedAddress){
            swal({
                title: 'Hold on!',
                text: "Please make sure all the form fields are filled out",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(this.state.acceptableDistance <= 0){
            swal({
                title: 'Hold on!',
                text: "Please make sure distance is greater than 0",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        const canPlayOutside = this.state.canPlayOutside || false;
        this.setState({loading:true})
        const vm = this;
        let notAcceptableLocationMessage = vm.state.notAcceptableLocationMessage.toString('html');
        if(notAcceptableLocationMessage === "<p><br></p>"){
            notAcceptableLocationMessage = "";
        }
        geocoder.geocode({'address':this.state.formattedAddress}, function(results, status) {
            if (status === 'OK') {
                if(results.length === 1){
                    const tenantVariablesCopy = tenantVariables;
                    tenantVariablesCopy["formattedAddress"] = vm.state.formattedAddress;
                    tenantVariablesCopy["acceptableDistance"] = vm.state.acceptableDistance;
                    tenantVariablesCopy["longitude"] = results[0].geometry.location.lng();
                    tenantVariablesCopy["latitude"] = results[0].geometry.location.lat();
                    tenantVariablesCopy["locationPermissionsBody"] = vm.state.locationPermissionsBody || "";
                    tenantVariablesCopy["locationPermissionsHeader"] = vm.state.locationPermissionsHeader || "";
                    tenantVariablesCopy["notAcceptableLocationMessage"] = notAcceptableLocationMessage;
                    tenantVariablesCopy["notAcceptableLocationHeader"] = vm.state.notAcceptableLocationHeader || "";
                    tenantVariablesCopy["canPlayOutside"] = canPlayOutside;
                    vm.setState({
                        tenantVariables:  tenantVariablesCopy,
                        loading: false
                    });
                    swal({
                        title: 'Updates Successful',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    vm.setState({loading:false})
                    swal({
                        title: 'Hold on!',
                        text: "Too many locations have that address! Add more detail to get only 1 address",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                vm.setState({loading:false})
                swal({
                    title: 'Hold on!',
                    text: 'Finding address was not successful because ' + status,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    updateList(listToUpdate){
        const listName = listToUpdate + 'List';
        let listToSave = this.state[listName] || "";
        listToSave = listToSave.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if(listToSave){
            const splitList = listToSave.split(/[ ,\s]+/);
            for(const splitListIndex in splitList) {
                const splitItem = splitList[splitListIndex];
                let valid;
                if (splitItem.indexOf('@') === -1) {
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if (!valid) {
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        base.update(`tenantVariables`, {
            data: {[listName]: rejoinedCleanedDomainsEmails},
            then(err){
                if(err){
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    console.log(err)
                } else {
                    swal({
                        title: 'List Updated',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                }
            }
        })
    }

    handleRichTextChange = (name, text) => {
        this.setState({ [name]:  text});
    }

    updateQuestionText(whichQuestion) {
        const tenantVariables = this.state.tenantVariables || {};
        let whichQuestionToUpdate = whichQuestion === "two" ? "yesNoQuestionText2" : "yesNoQuestionText"
        let yesNoQuestionText = this.state[whichQuestionToUpdate] || "";
        if (isEmptyHtmlEntity(yesNoQuestionText)) {
            yesNoQuestionText = "";
        }
        tenantVariables[whichQuestionToUpdate] = yesNoQuestionText.toString('html');

        this.setState({
            tenantVariables: tenantVariables
        }, () => {
            swal({
                title: 'Updates Successful',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            });
        });
    }

    updateText(variable){
        const tenantVariablesCopy = this.state.tenantVariables || {};
        let textToUpdate = this.state[variable].toString('html');
        if(textToUpdate === "<p><br></p>"){
            textToUpdate = "";
        }
        tenantVariablesCopy[variable] = textToUpdate;
        this.setState({
            tenantVariables:  tenantVariablesCopy,
            loading: false
        });
        swal({
            title: 'Updates Successful',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    mlbUpdateMessaging(){
        const tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy['notAcceptableLocationHeader'] = this.state.notAcceptableLocationHeader;
        let notAcceptableLocationMessage = this.state.notAcceptableLocationMessage.toString('html');
        if(notAcceptableLocationMessage === "<p><br></p>"){
            notAcceptableLocationMessage = "";
        }
        tenantVariablesCopy['notAcceptableLocationMessage'] = notAcceptableLocationMessage;
        this.setState({ tenantVariables:  tenantVariablesCopy});
        swal({
            title: 'Updates Successful',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    returnCorrectRichTextObject(text){
        if(!text){
            return RichTextEditor.createEmptyValue();
        }
        if(typeof text === 'string') {
            text = RichTextEditor.createValueFromString(text, 'html')
        }
        return text;
    }

    readyRichTextForSubmit(text){
        text = text || RichTextEditor.createEmptyValue();
        text = text.toString('html');
        if(text === "<p><br></p>"){
            text = "";
        }
        return text
    }

    async updateCustomDropdownOptions() {
        const returnedArray = detectHowManyCodesEntered(this.state.customDropdownOptions, false, true);
        const responseToAlert = await swal({
            title: 'Save Dropdown Options?',
            text: 'Are you sure you want to do this?  We have detected ' + returnedArray.length.toString() + ' potential responses',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Save',
        });
        if (responseToAlert.value) {
            base.update(`tenantVariables`, {
                data: { customDropdownOptions: this.state.customDropdownOptions },
                then(err) {
                    if (err) {
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        swal({
                            title: 'Updated',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        });
                    }
                }
            });
        }
    }

    render() {
        const variables = this.state.tenantVariables || {};
        const isMlbTeam = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const collectName = variables.collectName;
        const nameInEmail = variables.nameInEmail;
        const noMandatoryTermsAndConditions = variables.noMandatoryTermsAndConditions;
        const collectZipCode = variables.collectZipCode;
        const collectCity = variables.collectCity;
        const collectState = variables.collectState;
        const collectOptIn = variables.collectOptIn;
        const collectOptInTwo = variables.collectOptInTwo;
        const collectYesNoQuestion = variables.collectYesNoQuestion;
        const collectYesExplanation = variables.collectYesExplanation;
        const yesNoQuestionText = this.state.yesNoQuestionText || RichTextEditor.createEmptyValue();
        const collectYesNoQuestion2 = variables.collectYesNoQuestion2;
        const collectYesExplanation2 = variables.collectYesExplanation2;
        const yesNoQuestionText2 = this.state.yesNoQuestionText2 || RichTextEditor.createEmptyValue();
        const notDefaultCheckedOptIn = variables.notDefaultCheckedOptIn;
        const collectOptInTwoNotDefaultCheck = variables.collectOptInTwoNotDefaultCheck;
        const collectBirthday = variables.collectBirthday;
        const collectPhoneNumber = variables.collectPhoneNumber;
        const formBirthday = variables.formBirthday;
        const optionalPhoneNumber = variables.optionalPhoneNumber;
        const keepFansLoggedIn = variables.keepFansLoggedIn;
        const allowAnonymousLogin = variables.allowAnonymousLogin;
        const doNotCollectEmail = variables.doNotCollectEmail;
        const collectCustomDropdown = variables.collectCustomDropdown;
        const collectDistance = variables.collectDistance;
        const collectFullAddress = variables.collectFullAddress;
        const gdprCompliance = variables.gdprCompliance;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const doNotVerifyAmericanZipCode = variables.doNotVerifyAmericanZipCode;
        let formattedAddress = this.state.formattedAddress;
        let locationPermissionsBody = this.state.locationPermissionsBody;
        let locationPermissionsHeader = this.state.locationPermissionsHeader;
        const sanitizeEmails = variables.sanitizeEmails;
        const canPlayOutside = this.state.canPlayOutside;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage || RichTextEditor.createEmptyValue();
        const promotionText = this.state.promotionText || RichTextEditor.createEmptyValue();
        let promotionTextTwo = this.state.promotionTextTwo || RichTextEditor.createEmptyValue();
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;
        const gdprComplianceMessage = this.state.gdprComplianceMessage || RichTextEditor.createEmptyValue();
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if(!isMlbTeam){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                {!isMlbTeam ?
                    <>
                        <div className="container-out container-left-rules" style={{width: "50%", float:"left"}}>
                            <div className="admin-form-box">
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY name field to the login flow</p>
                                    <input type="checkbox" checked={collectName} id="collectName" name="collectName" onClick={()=> this.selectOption("collectName")}/>
                                </div>
                                {collectName &&
                                    <div className="form-group">
                                        <label htmlFor="nameInEmail">Include Name In Email</label>
                                        <br/>
                                        <input type="checkbox" checked={nameInEmail} id="nameInEmail" name="nameInEmail" onClick={()=> this.selectOption("nameInEmail")}/>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectZipCode">Collect ZipCode</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY zip code field to the login flow</p>
                                    <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={()=> this.selectOption("collectZipCode")}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectFullAddress">Collect Full Address</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY address field to the login flow</p>
                                    <input type="checkbox" checked={collectFullAddress} id="collectFullAddress" name="collectFullAddress" onClick={()=> this.selectOption("collectFullAddress")}/>
                                </div>
                                {collectFullAddress &&
                                    <div className="form-group">
                                        <label htmlFor="placeHolderAddress">Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which address to enter</p>
                                        <input value={variables.placeHolderAddress} className="form-control" type="text" id="placeHolderAddress" name="placeHolderAddress" placeholder="Mailing Address" onChange={this.handleTextChange} />
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectBirthday">Collect Birthday</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY birthday input field</p>
                                    <input type="checkbox" checked={collectBirthday} id="collectBirthday" name="collectBirthday" onClick={()=> this.selectOption("collectBirthday")}/>
                                    <div style={{display:collectBirthday ? "":"none", margin: 5}}>
                                        <div className="form-group" style={{display: collectBirthday? "":"none"}}>
                                            <label htmlFor="allowedAge">Age</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter an age that the user must be above to play</p>
                                            <input type="number" id="allowedAge" name="allowedAge" className="form-control" value={variables.allowedAge} onChange={this.handleTextChange}/>
                                        </div>
                                        <label className="radio-inline" style={{marginRight:5}}><input type="radio" name="formBirthday" id="formBirthday" onChange={()=>this.selectOption("formBirthday")} checked={formBirthday}/> In Form Birthday Input</label>
                                        <label className="radio-inline"><input type="radio" name="formBirthday" id="formBirthday" onChange={()=>this.selectOption("formBirthday")} checked={!formBirthday}/> Page Birthday Input</label>
                                        {!formBirthday &&
                                            <div className="form-group">
                                                <label htmlFor="ageVerificationPageButtonText">Age Verification Page Button Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to give more specific button actions</p>
                                                <input value={variables.ageVerificationPageButtonText} className="form-control" type="text" id="ageVerificationPageButtonText" name="ageVerificationPageButtonText" placeholder="Submit" onChange={this.handleTextChange} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this
                                        box
                                        to add a MANDATORY phone number field to the login flow</p>
                                    <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber"
                                           name="collectPhoneNumber"
                                           onClick={() => this.selectOption("collectPhoneNumber")}/>
                                    {collectPhoneNumber &&
                                        <div>
                                            <label className="radio-inline" style={{marginRight:5}}><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={()=>this.selectOption("optionalPhoneNumber")} checked={optionalPhoneNumber}/> Optional</label>
                                            <label className="radio-inline"><input type="radio" name="optionalPhoneNumber" id="optionalPhoneNumber" onChange={()=>this.selectOption("optionalPhoneNumber")} checked={!optionalPhoneNumber}/> Mandatory</label>
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectOptIn">Turn on Optional Opt-In</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={()=> this.selectOption("collectOptIn")}/>
                                </div>
                                {collectOptIn &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="notDefaultCheckedOptIn">Turn Off Default Checked Opt-In</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This will default the opt-in check box to be UNCHECKED</p>
                                            <input type="checkbox" checked={notDefaultCheckedOptIn} id="notDefaultCheckedOptIn" name="notDefaultCheckedOptIn" onClick={()=> this.selectOption("notDefaultCheckedOptIn")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="promotionText">Opt-In Text</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                            <RichTextEditor toolbarConfig={toolbarConfig} id="promotionText" name="promotionText" value={promotionText} onChange={(text)=>this.handleRichTextChange('promotionText', text)} placeholder="Opt in to our sweet offer"/>
                                            {/*<button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updatePromotionText()}><span className="fa fa-arrow-circle-o-up"/> Update Promotion Text</button>*/}
                                            {/*<textarea id="promotionText" name="promotionText" className="form-control" value={variables.promotionText} onChange={this.handleTextChange}/>*/}
                                        </div>
                                    </>
                                }
                                { (collectOptIn || collectOptInTwo) &&
                                    <div className="form-group">
                                        <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                        <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={()=> this.selectOption("collectOptInTwo")}/>
                                    </div>
                                }
                                {collectOptInTwo &&
                                    <>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Text That Shows Next To Opt-In Check Box</p>
                                        <RichTextEditor toolbarConfig={toolbarConfig} id="promotionTextTwo" name="promotionTextTwo" value={promotionTextTwo} onChange={(text)=>this.handleRichTextChange('promotionTextTwo', text)} placeholder="Opt in to our sweet offer"/>
                                        {/*<textarea className="form-control" id="promotionTextTwo" name="promotionTextTwo" value={variables.promotionTextTwo} onChange={this.handleTextChange}/>*/}
                                        <div className="form-group">
                                            <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to have the Opt-In field not default checked</p>
                                            <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={()=> this.selectOption("collectOptInTwoNotDefaultCheck")}/>
                                        </div>
                                    </>
                                }
                                {(collectOptIn || collectOptInTwo) &&
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updatePromotionText()}><span className="fa fa-arrow-circle-o-up"/> Update Opt-In Text</button>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectCity">Collect City</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY city field to the login flow</p>
                                    <input type="checkbox" checked={collectCity} id="collectCity" name="collectCity" onClick={() => this.selectOption("collectCity")} />
                                </div>
                                {collectCity &&
                                    <div className="form-group">
                                        <label htmlFor="cityPlaceholder">City Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which city to enter</p>
                                        <input value={variables.cityPlaceholder} className="form-control" type="text" id="cityPlaceholder" name="cityPlaceholder" placeholder="City" onChange={this.handleTextChange} />
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectState">Collect State</label>
                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a MANDATORY state field to the login flow</p>
                                    <input type="checkbox" checked={collectState} id="collectState" name="collectState" onClick={() => this.selectOption("collectState")} />
                                </div>
                                {collectState &&
                                    <div className="form-group">
                                        <label htmlFor="statePlaceholder">State Placeholder Text</label>
                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans which state to enter</p>
                                        <input value={variables.statePlaceholder} className="form-control" type="text" id="statePlaceholder" name="statePlaceholder" placeholder="State" onChange={this.handleTextChange} />
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="showAdvancedSettings">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                {this.state.advanced &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                                            <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add MANDATORY verification of distance from stadium</p>
                                            <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={()=> this.selectOption("collectDistance")}/>
                                            <span style={{display: collectDistance ? 'block' : 'none'}}>
                                    <div>
                                        <label htmlFor="formattedAddress" style={{marginTop: 5, marginBottom: 0}}>Address</label>
                                        <br/>
                                        <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address"/>
                                        <br/>
                                        <label htmlFor="locationPermissionsHeader" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="locationPermissionsHeader" name="locationPermissionsHeader" value={locationPermissionsHeader} onChange={this.handleChange} placeholder="Location Permissions Header"/>
                                        <br/>
                                        <label htmlFor="locationPermissionsBody" style={{marginTop: 5, marginBottom: 0}}>Requesting Location Permissions Body</label>
                                        <br/>
                                        <textarea className="form-control" id="locationPermissionsBody" name="locationPermissionsBody" value={locationPermissionsBody} onChange={this.handleChange} placeholder="Location Permissions Body"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationHeader" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Not Acceptable Location Header"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationMessage" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Message</label>
                                        <br/>
                                        <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage" name="notAcceptableLocationMessage" value={notAcceptableLocationMessage} onChange={(text) => this.handleRichTextChange('notAcceptableLocationMessage', text)} placeholder="Not Acceptable Location Message"/>
                                        <label htmlFor="acceptableDistance" style={{marginTop:5,marginBottom:0}}>Distance (Miles)</label> <input type="checkbox" onClick={()=> this.setState({canPlayOutside:false})} checked={!canPlayOutside}/> Fans Can Play Within <input onClick={()=> this.setState({canPlayOutside:true})} type="checkbox" checked={canPlayOutside}/> Fans Can Play Outside
                                        <br/>
                                        <input className="form-control" type="number" id="acceptableDistance" step="0.1" name="acceptableDistance" min="0" value={acceptableDistance} onChange={this.handleChange} placeholder=""/>
                                    </div>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateDistance()}><span className="fa fa-arrow-circle-o-up"/> Update Distance Variables</button>
                                </span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="doNotCollectEmail">DO NOT Collect Email</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to remove collecting of email. ONLY CHECK THIS IF YOU KNOW WHAT YOU ARE DOING</p>
                                            <input type="checkbox" checked={doNotCollectEmail} id="doNotCollectEmail" name="doNotCollectEmail" onClick={()=> this.selectOption("doNotCollectEmail")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And Conditions</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to remove the MANDATORY confirm rules and regs checkbox<br/>Rules and Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules and Regs Tab</u></a></p>
                                            <input type="checkbox" checked={noMandatoryTermsAndConditions} id="noMandatoryTermsAndConditions" name="noMandatoryTermsAndConditions" onClick={()=> this.selectOption("noMandatoryTermsAndConditions")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sanitizeEmails">Sanitize Emails</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to map accounts with the same sanitized email to the same account<br/>(could lead to issues with users having the same email address)</p>
                                            <input type="checkbox" checked={sanitizeEmails} id="sanitizeEmails" name="sanitizeEmails" onClick={()=> this.selectOption("sanitizeEmails")}/>
                                        </div>
                                        {collectZipCode &&
                                            <div className="form-group">
                                                <label htmlFor="doNotVerifyAmericanZipCode">Do Not Verify American Zip Code?</label>
                                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Do not verify American Zip Code. Check this only if the zip codes you are collecting are outside the U.S.</p>
                                                <input type="checkbox" checked={doNotVerifyAmericanZipCode} id="doNotVerifyAmericanZipCode" name="doNotVerifyAmericanZipCode" onClick={()=> this.selectOption("doNotVerifyAmericanZipCode")}/>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="blockList">Block List Emails/Domains</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be blocked from logging in.</p>
                                            <textarea className="form-control" id="blockList" name="blockList" value={this.state.blockList} onChange={this.handleChange}/>
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateList('block')}><span className="fa fa-arrow-circle-o-up"/> Update Block List</button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="allowList">Allow List Emails/Domains</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be allowed to login, all other emails/domains will be blocked. This will SUPERSEDE any emails/domains on the block list and let them play</p>
                                            <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange}/>
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateList('allow')}><span className="fa fa-arrow-circle-o-up"/> Update Allow List</button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="keepFansLoggedIn">Keep Fans Logged In?</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to keep fans logged in so they will not have to keep filling in the login form</p>
                                            <input type="checkbox" checked={keepFansLoggedIn} id="keepFansLoggedIn" name="keepFansLoggedIn" onClick={()=> this.selectOption("keepFansLoggedIn")}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="collectYesNoQuestion">Collect Yes Or No Question</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a Yes No Question To Your Login Form</p>
                                            <input type="checkbox" checked={collectYesNoQuestion} id="collectYesNoQuestion" name="collectYesNoQuestion" onClick={() => this.selectOption("collectYesNoQuestion")} />
                                        </div>
                                        {collectYesNoQuestion &&
                                            <>
                                                <RichTextEditor
                                                    toolbarConfig={toolbarConfig}
                                                    id="yesNoQuestionText"
                                                    name="yesNoQuestionText"
                                                    value={yesNoQuestionText}
                                                    onChange={(text) => this.handleRichTextChange('yesNoQuestionText', text)}
                                                    placeholder="A great yes or no question"/>
                                                <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateQuestionText('one')}>
                                                    <span className="fa fa-arrow-circle-o-up" /> Update Question Text
                                                </button>
                                                <div className="form-group">
                                                    <label htmlFor="collectYesExplanation">Collect Yes Explanation?</label>
                                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a mandatory input for an explanation of why yes</p>
                                                    <input type="checkbox" checked={collectYesExplanation} id="collectYesExplanation" name="collectYesExplanation" onClick={() => this.selectOption("collectYesExplanation")} />
                                                </div>
                                                {collectYesExplanation &&
                                                    <div className="form-group">
                                                        <label htmlFor="yesExplanationPlaceHolderText">Yes Explanation Placeholder Text</label>
                                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans to explain why yes</p>
                                                        <input value={variables.yesExplanationPlaceHolderText} className="form-control" type="text" id="yesExplanationPlaceHolderText" name="yesExplanationPlaceHolderText" placeholder="Why yes?" onChange={this.handleTextChange} />
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="collectYesNoQuestion">Collect Yes Or No Question 2</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a Yes No Question To Your Login Form</p>
                                            <input type="checkbox" checked={collectYesNoQuestion2} id="collectYesNoQuestion2" name="collectYesNoQuestion2" onClick={() => this.selectOption("collectYesNoQuestion2")} />
                                        </div>
                                        {collectYesNoQuestion2 &&
                                            <>
                                                <RichTextEditor
                                                    toolbarConfig={toolbarConfig}
                                                    id="yesNoQuestionText2"
                                                    name="yesNoQuestionText2"
                                                    value={yesNoQuestionText2}
                                                    onChange={(text) => this.handleRichTextChange('yesNoQuestionText2', text)}
                                                    placeholder="A great yes or no question"/>
                                                <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateQuestionText('two')}>
                                                    <span className="fa fa-arrow-circle-o-up" /> Update Question Text
                                                </button>
                                                <div className="form-group">
                                                    <label htmlFor="collectYesExplanation2">Collect Yes Explanation?</label>
                                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add a mandatory input for an explanation of why yes</p>
                                                    <input type="checkbox" checked={collectYesExplanation2} id="collectYesExplanation2" name="collectYesExplanation2" onClick={() => this.selectOption("collectYesExplanation2")} />
                                                </div>
                                                {collectYesExplanation2 &&
                                                    <div className="form-group">
                                                        <label htmlFor="yesExplanationPlaceHolderText2">Yes Explanation Placeholder Text</label>
                                                        <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Use this to inform the fans to explain why yes</p>
                                                        <input value={variables.yesExplanationPlaceHolderText2} className="form-control" type="text" id="yesExplanationPlaceHolderText2" name="yesExplanationPlaceHolderText2" placeholder="Why yes?" onChange={this.handleTextChange} />
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="gdprCompliance">GDPR Compliance</label>
                                            <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check this box to add a Yes No Question To Your Login Form</p>
                                            <input type="checkbox" checked={gdprCompliance} id="gdprCompliance" name="gdprCompliance" onClick={() => this.selectOption("gdprCompliance")}/>
                                        </div>
                                        {gdprCompliance &&
                                            <>
                                                <label htmlFor="gdprComplianceHeader" style={{marginTop: 5, marginBottom: 0}}>GDPR Pop Up Header</label>
                                                <br/>
                                                <input className="form-control" type="text" id="gdprComplianceHeader" name="gdprComplianceHeader" value={variables.gdprComplianceHeader} onChange={this.handleTextChange} placeholder="Make Sure To Read The Rules!"/>
                                                <br/>
                                                <label htmlFor="gdprComplianceMessage" style={{marginTop: 5, marginBottom: 0}}>GDPR Pop Up Message</label>
                                                <br/>
                                                <RichTextEditor toolbarConfig={toolbarConfig} id="gdprComplianceMessage" name="gdprComplianceMessage" value={gdprComplianceMessage} onChange={(text) => this.handleRichTextChange( 'gdprComplianceMessage', text)} placeholder=""/>
                                                <button className="btn btn-primary btn-lg update-button" style={{marginTop: 5}} onClick={() => this.updateText("gdprComplianceMessage")}><span className="fa fa-arrow-circle-o-up"/> Update GDPR Compliance Messaging</button>
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="collectCustomDropdown">Collect Custom Dropdown</label>
                                            <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Check this box to add custom drop down</p>
                                            <input type="checkbox" checked={collectCustomDropdown} id="collectCustomDropdown" name="collectCustomDropdown" onClick={() => this.selectOption("collectCustomDropdown")} />
                                        </div>
                                        {collectCustomDropdown &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="customDropdownOptions">Custom Dropdown Options</label>
                                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>Enter any options you want to show up in the dropdown, separate each option with the same separator. For example separating with a comma, space, or line break</p>
                                                    <textarea className="form-control" id="customDropdownOptions" name="customDropdownOptions" value={this.state.customDropdownOptions} onChange={this.handleChange} />
                                                    <button className="btn btn-primary btn-lg update-button" style={{ marginTop: 5 }} onClick={() => this.updateCustomDropdownOptions()}><span className="fa fa-arrow-circle-o-up" /> Update Custom Dropdown Options</button>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="customDropdownPlaceholderText">Custom Dropdown Placeholder Text</label>
                                                    <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This is the text that will show up as the first option in the dropdown but will not count as a value</p>
                                                    <input value={variables.customDropdownPlaceholderText} className="form-control" type="text" id="customDropdownPlaceholderText" name="customDropdownPlaceholderText" placeholder="" onChange={this.handleTextChange} />
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                            <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                                <p style={{fontSize:'20px', fontWeight:'bold'}}>What Data Should I Gather?</p>
                                <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                                    Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                            </div>
                        </div>
                    </>
                    :
                    <div className="container-out container-left-rules">
                        <div className="admin-form-box">
                            <div className="form-group">
                                <label htmlFor="noMandatoryTermsAndConditions">Turn OFF Mandatory Terms And
                                    Conditions</label>
                                <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>Check
                                    this
                                    box to remove the MANDATORY confirm rules and regs checkbox<br/>Rules
                                    and
                                    Regs Text and Link come from the <a href="/setuprulesandregs"><u>Rules
                                        and
                                        Regs Tab</u></a></p>
                                <input type="checkbox" checked={noMandatoryTermsAndConditions}
                                       id="noMandatoryTermsAndConditions"
                                       name="noMandatoryTermsAndConditions"
                                       onClick={() => this.selectOption("noMandatoryTermsAndConditions")}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="collectDistance">Turn On/Off Check-In Requirement</label>
                                <br/>
                                <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={()=> this.selectOption("collectDistance")}/>
                                <span style={{display: collectDistance ? 'block' : 'none' }}>
                                    <div>
                                        <label htmlFor="notAcceptableLocationHeader" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Hold On"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationMessage" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Message</label>
                                        <br/>
                                        <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage" name="notAcceptableLocationMessage" value={notAcceptableLocationMessage} onChange={(text) => this.handleRichTextChange('notAcceptableLocationMessage', text)} placeholder="Please check in on the MLB app before playing!"/>
                                    </div>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.mlbUpdateMessaging()}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
         </div>
        );
    }
}

export default SetUpLoginVariables;
